import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import StarsBackground from "./StarsBackground";

class Header extends Component {
  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var occupation = this.props.data.occupation;
      var description = this.props.data.description;
      var city = this.props.data.address.city;
      var networks = this.props.data.social.map(function (network) {
        return (
          <li key={network.name}>
            {network.skipUrl ? (
              <i className={network.className}></i>
            ) : (
              <a href={network.url}>
                <i className={network.className}></i>
              </a>
            )}
          </li>
        );
      });
    }

    return (
      <header id="home">
        <StarsBackground />

        <div className="row banner">
          <div className="banner-text">
            <h1 className="responsive-headline">Hello World!</h1>
            <h1 className="responsive-headline">I'm {name}</h1>
            <hr />
            <ul className="social">{networks}</ul>
          </div>
        </div>

        <p className="requestOrientation">
          <FontAwesomeIcon icon={faCirclePlay} />
        </p>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
